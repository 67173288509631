import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import WingSvg from '../atoms/wingSvg'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  font-size: 16px;

  h1 {
    border-bottom: solid 2px #243650;
    position: relative;
    display: inline-block;
    width: auto;
    padding: 0 6px 2px 0;
    margin-bottom: 10px;

    .svg-icon {
      width: 24px;
      height: 24px;
      position: absolute;
      right: -22px;
      bottom: -3px;
    }
  }

  section {
    display: flex;
    padding: 20px 25px 20px 20px;
    background: #f2faf9;
    border-radius: 5px;
    justify-content: space-between;

    h2 {
      font-size: 22px;
      color: #ff9600;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      img {
        width: 65px;
        margin: 0 5px 0 0;
        height: auto;
      }
    }

    p {
      margin: 0 0 5px 5px;
    }

    .p-function-link {
      position: relative;
      top: 9px;
      right: 2px;
      left: 20px;

      &:before {
        display: block;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 5px;
        left: -14px;
        border-left: 8px solid #3ec7b3;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
      }
    }

    .p-screen-wrap {
      width: 260px;
      height: auto;
      margin: 5px 5px 0;

      img {
        width: 100%;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      }
    }
  }
`

/**
 * コラム記事下部：キンクラの便利な機能レイアウト_特別休暇設定
 * @constructor
 */
const usefulFunctionLayout020 = () => (
  <Wrapper>
    <h1>
      キンクラの便利な機能
      <WingSvg />
    </h1>
    <section>
      <div>
        <h2>
          <img
            src="/images/official/function/imgFunction37.png"
            alt="キンクラの特別休暇設定"
          />
          特別休暇設定
        </h2>
        <p>企業独自の特別な休暇を登録することができます。</p>
        <Link className="p-function-link" to={useRouteParam('/function/')}>
          キンクラの機能を見る
        </Link>
      </div>
      {/*
      <div className="p-screen-wrap">
        <img src="/images/column/functions/f007.jpg" alt="キンクラの残業管理" />
      </div> */}
    </section>
  </Wrapper>
)

export default usefulFunctionLayout020
