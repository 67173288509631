import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  z-index: 1;
  margin-bottom: 20px;

  p {
    padding-bottom: 0 !important;
  }

  a {
    color: #00b5a6;
    padding-left: 10px;
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  link: string
  label: string
}

/**
 * コラム記事詳細：関連コラム誘導用リンク
 * @constructor
 */
const ColumnRelationLink = (props: Props): JSX.Element => {
  const routePram = useRouteParam(props.link)

  return (
    <Wrapper className="relation-link">
      <p>
        関連記事：
        <a href={routePram}>{props.label}</a>
      </p>
    </Wrapper>
  )
}
export default ColumnRelationLink
