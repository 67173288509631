import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'
import ColumnRelationLink from './columnRelationLink'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0026 = () => (
  <Wrapper>
    <ColumnH1 label="特別休暇とは？基礎知識や種類、他の休暇との違いを紹介します" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      <img src="/images/column/details/c0026.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        労働基準法で定める年次有給休暇は労働法制上唯一賃金支払い義務を課しており、賃金を支払わないという選択肢はありません。
        <br />
        近年、労働基準法で定める年次有給休暇とは別に従業員にインセンティブを付与する意味合いで
        <span>「特別休暇」</span>を定めるという企業が増えています。
        <br />
        今回は特別休暇と法定休暇、法定外休暇にフォーカスをあて解説していきます。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">法定休暇と法定外休暇の違い</a>
          </li>
          <li>
            <a href="#toc2">休暇を申請する場合に賃金は支払われるのか</a>
          </li>
          <li>
            <a href="#toc3">法定外休暇（特別休暇）の種類</a>
            <ul>
              <li>
                <a href="#toc3-1">夏季休暇</a>
              </li>
              <li>
                <a href="#toc3-2">病気休暇</a>
              </li>
              <li>
                <a href="#toc3-3">慶弔休暇</a>
              </li>
              <li>
                <a href="#toc3-4">教育訓練休暇</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#toc4">有給休暇取得義務化と計画的付与</a>
          </li>
          <li>
            <a href="#toc5">最後に</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>法定休暇と法定外休暇の違い</h1>
      </div>
      <p>
        法定休暇とは法律で明記されている休暇のことで、
        <span>
          労働基準法で定める年次有給休暇や生理休暇、育児介護休業法で定める子の看護休暇など
        </span>
        が挙げられます。
        <br />
        反対に法定外休暇とは法律上の定めはないものの
        <span>企業独自に定めた休暇で特別休暇</span>
        とも呼ばれます。違いを挙げるとすれば法律上の規定があるか否かとなります。法定休暇は会社が採用するか否かの選択の余地がなく、法律上当然に従業員の権利として存在するということです。
      </p>
      <div id="toc2">
        <h1>休暇を申請する場合に賃金は支払われるのか</h1>
      </div>
      <p>
        年次有給休暇を除き、賃金支払い義務はありません。反対に年次有給休暇以外であれば、賃金を支払うか否かは
        <span>会社が決める</span>ことができます。
        <br />
        例えば生理休暇について、法律上賃金支払い義務はありませんので、無給としても違法ではありませんが、取得促進を推し進めるために有給とすることは可能です。注意点として有給の日数（例えば有給は2日までとする）を定めておくことは可能ですが、生理休暇自体を有給の日数以上与えることを明らかにしておくことが求められます。
      </p>
      <div id="toc3">
        <h1>法定外休暇（特別休暇）の種類</h1>
      </div>
      <div id="toc3-1">
        <h2>夏季休暇</h2>
      </div>
      <p>
        冷房設備が整っているとはいえ、夏の蒸し暑い期間は出勤途上で相当の体力を消耗してしまうことも想像に難くありません。例えば対象期間を7/1から9/30までとし、この期間に取得するよう周知をし、部署単位で計画表を作成後に交代で取得させる企業があります。また、お盆期間に限り、取得日を社内で特定する企業もあります。
        <br />
        <br />
        尚、取得方法としては、分割取得が可能な場合、お盆の込み合う時期を避け、一例として子供を養育する従業員の場合、夏休みがスタートした7月下旬に数日取得し、他の時季に再度取得するという取得例があります。
      </p>
      <div id="toc3-2">
        <h2>病気休暇</h2>
      </div>
      <p>
        医療の発展等により人生100年時代が到来しています。当然、長く働けば働くほど、何らかの病気に罹患する可能性も高くなります。そして、現代は治療を継続しながら働くことも珍しくなくなりました。従業員目線では有給休暇は可能な限りいざという時（例えば長期入院時）まで残しておきたいという希望があり、積極的に申請しないというケースもあります。しかし、「欠勤」となると出勤している他の従業員間で色々な憶測が飛び交ってしまう余地があり、周囲の印象も必ずしも良いとは言えないことから、「病気休暇」を設けるという企業もあります。
        <br />
        病気休暇については、夏季休暇のように夏季に限定されるということはなく、夏季休暇以上に申請が見込まれる時季の幅も広がると言えます。また、
        <span>
          継続して4日以上労務不能状態（医師の証明が必要）となった場合は健康保険から傷病手当金が支給
        </span>
        されることからあえて有給とせず、無給とする企業もあります。もちろん、疾病の理由が業務上であった場合は労災保険から給付が行われますので、傷病手当金は受給できません。
      </p>
      <div id="toc3-3">
        <h2>慶弔休暇</h2>
      </div>
      <p>
        病気休暇と同様に長期間勤務することで、家族の冠婚葬祭と勤務日が重なることは珍しくありません。また、結婚式への出席などは相当前の期間からわかるものですが、不幸にも家族が急な事故に巻き込まれ他界した場合や、病気療養のため入院していたものの、病状が急変し他界したというケースは突発的に休まざるを得ないと言えます。
        <br />
        そのような場合に<span>慶弔休暇</span>
        を設定することで、年次有給休暇の残日数を気にすることなく、休むことができます。慶弔休暇は法定外休暇のため、有給にする義務はありませんが、毎年頻発するようなものではなく、従業員へのお祝いの気持ちやお悔やみの気持ちを込めて有給とする企業も多くあります。
      </p>
      <div id="toc3-4">
        <h2>教育訓練休暇</h2>
      </div>
      <p>
        企業である以上、利益を上げていくことが求められますが現代のように多くの情報が溢れかえる時代においては正しい知識と情報を掴み、業務に反映していくことが求められます。そこで、業務に関連した資格を取得するために、一定期間の休暇制度を設ける企業があります。
        <br />
        具体的には資格取得のため、学校に通う期間や、資格試験数日前の追い込み期間などに休暇を申請してもらうということです。短期的に休まれてしまうと当然、労働力の減少により、仕事が回らないということも想定されますが、長期的には資格取得を通じて、社内に還元してもらうことで、プラスになることもあります。
      </p>
      <Introduction003 />
      <div id="toc4">
        <h1>有給休暇取得義務化と計画的付与</h1>
      </div>
      <p>
        2019年4月1日以降、年10日以上有給休暇が付与される場合、付与日から1年以内に年5日以上取得させることが求められています。
        <br />
        そこで、法違反の回避と従業員満足度向上を目的に事業主側から有給休暇取得日を決める
        <span>「計画的付与」</span>
        があります。当初から定められている年末年始休業期間の前後に計画的付与を行うことで通常の年末年始休業のプラスαとして、長期のリフレッシュが可能となります。
        <br />
        これらは法律上設けられている制度のため、後述する任意に設ける特別休暇ではありませんが、労務管理能力に長けた事業所は導入していることが多い手法です。
      </p>
      <ColumnRelationLink
        link="https://www.kintaicloud.jp/column/details/C0022/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0026"
        label="有給休暇の取得義務化！企業がするべき対応やポイントとは"
      />
      <div id="toc5">
        <h1 className="read">最後に</h1>
      </div>
      <p>
        本来、有給休暇はどのような理由で申請するかは会社が干渉できず、従業員が自由に取得できますが、国民性を鑑みても有給休暇を全て使い切るという従業員は決して多くありません。また、職場の風土として有給休暇自体を申請しづらいという声も多くあります。
        <br />
        反対に、特定の取得理由が掲げられている休暇の方が、申請に当たっての心理的ハードルが低いことから、従業員目線では特別休暇の新設を歓迎されることが多くあります。
        <br />
        <br />
        また、会社としても業務の正常な運営に支障をきたさない範囲内で申請してもらうことで、労使間の信頼関係が強固になるのであれば、メリットは大きいと言えます。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0026
