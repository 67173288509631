import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0026 from '../../../components/molecules/columnDetailInner0026'
import UsefulFunctionLayout020 from '../../../components/molecules/usefulFunctionLayout020'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0026 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '特別休暇とは？種類や他の休暇との違い、扱いについて解説します',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="特別休暇とは？基礎知識や種類、他の休暇との違いを紹介します"
        description="特別休暇は、年次有給休暇とは別に従業員にインセンティブを付与する意味合いで企業独自に定める休暇です。賃金を支払うか否かは会社が決めることができます。"
        ogUrl="https://kintaicloud.jp/column/details/C0026/"
        ogType="article"
        ogTitle="特別休暇とは？基礎知識や種類、他の休暇との違いを紹介します"
        ogDescription="特別休暇は、年次有給休暇とは別に従業員にインセンティブを付与する意味合いで企業独自に定める休暇です。賃金を支払うか否かは会社が決めることができます。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0026.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0026 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout020 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0026

export const pageQuery = graphql`
  query C0026 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
